import actions from './actions';

const { NEWS_BLOCK_SUCCESS, RECENT_EDITORS_SUCCESS } = actions;

/* aio state */
const initialState = {
    newsBlock: [],
    recentEditors: []
}

/* aio reducer */
const dahsboardReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case NEWS_BLOCK_SUCCESS:
        return { ...state, newsBlock: data };
    case RECENT_EDITORS_SUCCESS:
        return { ...state, recentEditors: data };
    default:
        return state;
    }
};
export { dahsboardReducer };