const actions = {
    ALL_ADMINS_SUCCESS: 'ALL_ADMINS_SUCCESS',

    /* action all admin */
    allAdminsSuccess: data => {
        return {
            type: actions.ALL_ADMINS_SUCCESS,
            data,
        }
    }
}

export default actions