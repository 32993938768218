import actions from './actions';

const { ALL_AIO_ACTIF_SUCCESS, ALL_AIO_INACTIF_SUCCESS, SINGLE_AIO_SUCCESS, ADD_AIO_SUCCESS, ACTIVATE_AIO_SUCCESS, EXPORT_AIO_SUCCESS, EXPORT_ACTIVE_AIO_SUCCESS, POLLUTION_DATA, REFUELING_DATA, STAT_DATA, FILTER_AIO_SUCCESS, SINGLE_AIO_DATA_SUCCESS, ONLY_ACTIVE_AIOS, ONLY_BROKEN_DOWN_AIOS, ONLY_IN_STOCK_AIOS, ONLY_ASSIGNED_AIOS, ONLY_MAINTENANCE_AIOS, CONTACT_EDITOR_SUCCESS, MOBILE_POLLUTION_DATA, MOBILE_REFUELING_DATA, FILTER_AIO_ACTIVE_SUCCESS, TWENTY_IN_STOCK_AIOS, DELETE_AIO, DISSOCIER_AIO, ACTIVER_AIO, DESACTIVER_AIO, EDIT_AIO, CLEAR_DATA_AIO, DELETE_CARD_DATA_AIO, EXPORT_DATA_AIO_SUCCESS, AIO_IMAGE_SUCCESS, AIO_PDF_SUCCESS, AIO_PV_SUCCESS } = actions;

/* aio state */
const initialState = {
    aioActif: [],
    aioInactif: [],
    aioDetail: [],
    aioAdd: false,
    aioEdit: false,
    activateAIO: false,
    exportedData: [],
    pollutionData: [],
    mobilePollutionData: [],
    mobileRefuelingData: [],
    filter: [],
    filterActive: [],
    statisticData: [],
    aioCardData: [],
    OnlyActiveAios: [],
    OnlyMaintenanceAios: [],
    OnlyBrokenDownAios: [],
    OnlyInStockAios: [],
    OnlyAssignedAios: [],
    refuelingData: [],
    contactEditor: [],
    deleteAio: false,
    dissocierAio: false,
    activerAio: false,
    desactiverAio: false,
    cardDataDeleted: false,
    exportedAioData: [],
    imageAio: [],
    pdfAio: [],
    pvAio: []
}

/* aio reducer */
const AIOReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case ALL_AIO_ACTIF_SUCCESS:
            return { ...state, aioActif: data };
        case ALL_AIO_INACTIF_SUCCESS:
            return { ...state, aioInactif: data };
        case SINGLE_AIO_SUCCESS:
            return { ...state, aioDetail: data };
        case ADD_AIO_SUCCESS:
            return { ...state, aioAdd: data };
        case AIO_IMAGE_SUCCESS:
            return { ...state, imageAio: data };
            case AIO_PV_SUCCESS:
                return { ...state, pvAio: data };
        case AIO_PDF_SUCCESS:
            return { ...state, pdfAio: data };
        case EDIT_AIO:
            return { ...state, aioEdit: data };
        case ACTIVATE_AIO_SUCCESS:
            return { ...state, activateAIO: data };
        case SINGLE_AIO_DATA_SUCCESS:
            return { ...state, aioCardData: data };
        case EXPORT_AIO_SUCCESS:
            return { ...state, exportedData: data };
        case EXPORT_ACTIVE_AIO_SUCCESS:
            return { ...state, exportedData: data };
        case FILTER_AIO_SUCCESS:
            return { ...state, filter: data };
        case FILTER_AIO_ACTIVE_SUCCESS:
            return { ...state, filterActive: data };
        case POLLUTION_DATA:
            return { ...state, pollutionData: data };
        case MOBILE_POLLUTION_DATA:
            return { ...state, mobilePollutionData: data };
        case MOBILE_REFUELING_DATA:
            return { ...state, mobileRefuelingData: data };
        case REFUELING_DATA:
            return { ...state, refuelingData: data };
        case STAT_DATA:
            return { ...state, statisticData: data };
        case ONLY_ACTIVE_AIOS:
            return { ...state, OnlyActiveAios: data };
        case ONLY_MAINTENANCE_AIOS:
            return { ...state, OnlyMaintenanceAios: data };
        case ONLY_IN_STOCK_AIOS:
            return { ...state, OnlyInStockAios: data };
        case ONLY_ASSIGNED_AIOS:
            return { ...state, OnlyAssignedAios: data };
        case TWENTY_IN_STOCK_AIOS:
            return { ...state, TwentyInStockAios: data };
        case ONLY_BROKEN_DOWN_AIOS:
            return { ...state, OnlyBrokenDownAios: data };
        case CONTACT_EDITOR_SUCCESS:
            return { ...state, contactEditor: data };
        case DELETE_AIO:
            return { ...state, deleteAio: data };
        case DISSOCIER_AIO:
            return { ...state, dissocierAio: data };
        case ACTIVER_AIO:
            return { ...state, activerAio: data };
        case DESACTIVER_AIO:
            return { ...state, desactiverAio: data };
        case CLEAR_DATA_AIO:
            return { ...state, aioDetail: [], aioCardData: [], pollutionData: [], refuelingData: [], mobilePollutionData: [] };
        case DELETE_CARD_DATA_AIO:
            return { ...state, cardDataDeleted: data };
        case EXPORT_DATA_AIO_SUCCESS:
            return { ...state, exportedAioData: data };
        default:
            return state;
    }
};
export { AIOReducer };