import React from 'react';
import axios from 'axios';

export default function Loader() {
  /* Sates */
  let requests = [];

  /* Show Loader */
  axios.interceptors.request.use(
    function(config) {
      requests.push(config);
      return config;
    },
    function(error) {
      return Promise.reject(error);
    },
  );

  /* Hide Loader */
  axios.interceptors.response.use(
    function(response) {
      closeLoader(response);
      return response;
    },
    function(error) {
      closeLoader(error);
      return Promise.reject(error);
    },
  );

  /* Close Loader */
  function closeLoader(response) {
    let index = requests.findIndex(el => el.url === response.url);
    requests.splice(index, 1);
  }

  return (
    <>
      {/* {isLoading ? (
        <div className="spin">
          <Spin />
        </div>
      ) : null} */}
    </>
  );
}
