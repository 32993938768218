import actions from "./actions";

const { ALL_EDITOR_SUCCESS, DETAIL_EDITOR_SUCCESS, EDIT_EDITOR_SUCCESS, ALL_AIO_EDITED_SUCCESS, DELETE_EDITOR_SUCCESS,
    UPDATE_PASSWORD_SUCCESS, ADD_EDITOR_SUCCESS, EXPORT_EDITOR_SUCCESS, GET_CITIES_SUCCESS, CLEAR_DATA, FILTER_EDITOR_SUCCESS } = actions;

/* editor state */
const initialState = {
    editors: [],
    aioEdited: [],
    detailEditor: {},
    exportedData: [],
    updatedPassword: false,
    successAlert: false,
    deleteEditor: false,
    addEditor: false,
    cities: [],
    filter: []
}

/* editor reducer */
const editorReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case ALL_EDITOR_SUCCESS:
            return {
                ...state,
                editors: data,
            };
        case DETAIL_EDITOR_SUCCESS:
            return {
                ...state,
                detailEditor: data,
            };
        case CLEAR_DATA:
            return {
                ...state,
                detailEditor: {},
            };
        case EDIT_EDITOR_SUCCESS:
            return {
                ...state,
                successAlert: data,
            };
        case ALL_AIO_EDITED_SUCCESS:
            return {
                ...state,
                aioEdited: data,
            };
        case UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                successAlert: data,
            };
        case ADD_EDITOR_SUCCESS:
            return {
                ...state,
                addEditor: data,
            };
        case EXPORT_EDITOR_SUCCESS:
            return {
                ...state,
                exportedData: data
            };
        case GET_CITIES_SUCCESS:
            return {
                ...state,
                cities: data
            };
        case FILTER_EDITOR_SUCCESS:
            return {
                ...state,
                filter: data,
            };
        case DELETE_EDITOR_SUCCESS:
            return {
                ...state,
                deleteEditor: data,
            };
        default:
            return state
    };
};

export { editorReducer };