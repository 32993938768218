const actions = {
    ALL_AIO_ACTIF_SUCCESS: 'ALL_AIO_ACTIF_SUCCESS',
    ALL_AIO_INACTIF_SUCCESS: 'ALL_AIO_INACTIF_SUCCESS',
    SINGLE_AIO_SUCCESS: 'GET_AIO_DETAIL',
    ADD_AIO_SUCCESS: 'ADD_AIO_SUCCESS',
    ACTIVATE_AIO_SUCCESS: 'ACTIVATE_AIO_SUCCESS',
    ONLY_ACTIVE_AIOS: 'ONLY_ACTIVE_AIOS',
    ONLY_MAINTENANCE_AIOS: 'ONLY_MAINTENANCE_AIOS',
    ONLY_IN_STOCK_AIOS: 'ONLY_IN_STOCK_AIOS',
    ONLY_ASSIGNED_AIOS: 'ONLY_ASSIGNED_AIOS',
    TWENTY_IN_STOCK_AIOS: 'TWENTY_IN_STOCK_AIOS',
    ONLY_BROKEN_DOWN_AIOS: 'ONLY_BROKEN_DOWN_AIOS',
    EXPORT_AIO_SUCCESS: 'EXPORT_AIO_SUCCESS',
    EXPORT_ACTIVE_AIO_SUCCESS: 'EXPORT_ACTIVE_AIO_SUCCESS',
    POLLUTION_DATA: 'POLLUTION_DATA',
    MOBILE_POLLUTION_DATA: 'MOBILE_POLLUTION_DATA',
    MOBILE_REFUELING_DATA: 'MOBILE_REFUELING_DATA',
    REFUELING_DATA: 'REFUELING_DATA',
    FILTER_AIO_SUCCESS: 'FILTER_AIO_SUCCESS',
    FILTER_AIO_ACTIVE_SUCCESS: 'FILTER_AIO_ACTIVE_SUCCESS',
    STAT_DATA: 'STAT_DATA',
    SINGLE_AIO_DATA_SUCCESS: 'SINGLE_AIO_DATA_SUCCESS',
    CONTACT_EDITOR_SUCCESS: 'CONTACT_EDITOR_SUCCESS',
    DELETE_AIO: 'DELETE_AIO',
    DISSOCIER_AIO: 'DISSOCIER_AIO',
    ACTIVER_AIO: 'ACTIVER_AIO',
    DESACTIVER_AIO: 'DESACTIVER_AIO',
    EDIT_AIO: 'EDIT_AIO',
    CLEAR_DATA_AIO: 'CLEAR_DATA_AIO',
    DELETE_CARD_DATA_AIO: 'DELETE_CARD_DATA_AIO',
    EXPORT_DATA_AIO_SUCCESS: 'EXPORT_DATA_AIO_SUCCESS',
    AIO_IMAGE_SUCCESS: 'AIO_IMAGE_SUCCESS',
    AIO_PDF_SUCCESS: 'AIO_PDF_SUCCESS',
    AIO_PV_SUCCESS: 'AIO_PV_SUCCESS',


    /* list aio actif */
    allAioActifSuccess: data => {
        return {
            type: actions.ALL_AIO_ACTIF_SUCCESS,
            data,
        }
    },

    /* list aio inactif */
    allAioInactifSuccess: data => {
        return {
            type: actions.ALL_AIO_INACTIF_SUCCESS,
            data,
        }
    },

    /* list only active aios */
    onlyActiveAiosSuccess: data => {
        return {
            type: actions.ONLY_ACTIVE_AIOS,
            data,
        }
    },
    /* contact editor*/
    contactEditor: data => {
        return {
            type: actions.CONTACT_EDITOR_SUCCESS,
            data,
        }
    },
    /* list only maintenance aios */
    onlyMaintenanceAiosSuccess: data => {
        return {
            type: actions.ONLY_MAINTENANCE_AIOS,
            data,
        }
    },

    /* list only broken down aios */
    onlyBrokenDownAiosSuccess: data => {
        return {
            type: actions.ONLY_BROKEN_DOWN_AIOS,
            data,
        }
    },

    /* list only in stock aios */
    onlyInStockAiosSuccess: data => {
        return {
            type: actions.ONLY_IN_STOCK_AIOS,
            data,
        }
    },

    /* list only assigned aios */
    onlyAssignedAiosSuccess: data => {
        return {
            type: actions.ONLY_ASSIGNED_AIOS,
            data,
        }
    },

    twentyInStockAiosSuccess: data => {
        return {
            type: actions.TWENTY_IN_STOCK_AIOS,
            data,
        }
    },

    /* detail aio */
    singleAioSuccess: data => {
        return {
            type: actions.SINGLE_AIO_SUCCESS,
            data,
        }
    },

    /* detail aio */
    addAioSuccess: data => {
        return {
            type: actions.ADD_AIO_SUCCESS,
            data,
        }
    },

    /* edit aio */
    editAioSuccess: data => {
        return {
            type: actions.EDIT_AIO,
            data,
        }
    },

    /* activate aio */
    activateAioSuccess: data => {
        return {
            type: actions.ACTIVATE_AIO_SUCCESS,
            data
        }
    },
    /* export aio */
    exportAioSuccess: data => {
        return {
            type: actions.EXPORT_AIO_SUCCESS,
            data,
        }
    },
    /* image aio */
    aioImageSuccess: data => {
        return {
            type: actions.AIO_IMAGE_SUCCESS,
            data,
        }
    },
    /* PV aio */
    aioPvSuccess: data => {
        return {
            type: actions.AIO_PV_SUCCESS,
            data,
        }
    },
    /* Pdf aio */
    aioPdfSuccess: data => {
        return {
            type: actions.AIO_PDF_SUCCESS,
            data,
        }
    },
    /* export active aio */
    exportActiveAioSuccess: data => {
        return {
            type: actions.EXPORT_ACTIVE_AIO_SUCCESS,
            data,
        }
    },
    /* data detail aio */
    singleAioDataSuccess: data => {
        return {
            type: actions.SINGLE_AIO_DATA_SUCCESS,
            data,
        }
    },

    /* pollution data */
    pollutionData: data => {
        return {
            type: actions.POLLUTION_DATA,
            data,
        }
    },
    /* mobile pollution data */
    mobilePollutionData: data => {
        return {
            type: actions.MOBILE_POLLUTION_DATA,
            data,
        }
    },
    /* mobile refueling data */
    mobileRefuelingData: data => {
        return {
            type: actions.MOBILE_REFUELING_DATA,
            data,
        }
    },
    /* refueling data */
    refuelingData: data => {
        return {
            type: actions.REFUELING_DATA,
            data,
        }
    },
    /* filtering data */
    filterSuccess: data => {
        return {
            type: actions.FILTER_AIO_SUCCESS,
            data,
        }
    },
    /* filtering data */
    filterActiveSuccess: data => {
        return {
            type: actions.FILTER_AIO_ACTIVE_SUCCESS,
            data,
        }
    },
    /* statistic data */
    statisticData: data => {
        return {
            type: actions.STAT_DATA,
            data,
        }
    },
    /* deleteAio */
    deleteAioSucces: data => {
        return {
            type: actions.DELETE_AIO,
            data,
        }
    },
    /* dissocierAio */
    dissocierAioSucces: data => {
        return {
            type: actions.DISSOCIER_AIO,
            data,
        }
    },
    /* activerAio */
    activerAioSucces: data => {
        return {
            type: actions.ACTIVER_AIO,
            data,
        }
    },
    /* desactiverAio */
    desactiverAioSucces: data => {
        return {
            type: actions.DESACTIVER_AIO,
            data,
        }
    },
    /* clear data */
    clearDataSuccess: data => {
        return {
            type: actions.CLEAR_DATA_AIO,
            data,
        }
    },
    /* delete card data */
    deleteCardDataSuccess: data => {
        return {
            type: actions.DELETE_CARD_DATA_AIO,
            data,
        }
    },
    /* export data aio */
    exportAioDataSuccess: data => {
        return {
            type: actions.EXPORT_DATA_AIO_SUCCESS,
            data,
        }
    },
}


export default actions;