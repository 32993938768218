/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API_HOST = "https://api.logikko.pre-prod.xyz/";
// const API_HOST = 'http://api.logikko.prod-projet.com/';
const CONTENT = {
  data: 'uploads/images',
};
const API = {
  auth: {
    login: 'admin/login',
    signUp: 'signup',
    refreshToken: 'token/refresh',
  },
  aio: {
    aios: 'api/inventories',
    apiAios: 'api/aios',
    activateAio: 'api/aios/activate/',
    aioCardData: 'api/aios/data/',
    exportAio: 'api/export/aios/inventorie',
    exportActiveAio: 'api/export/aios/active',
    pollutionData: 'api/pollutions/vehicle/',
    mobilePollutionData: 'api/pollution/vehicle/',
    mobileRefuelingData: 'api/refueling/vehicle/',
    refuelingData: 'api/refuelings/vehicle/',
    filterActive: 'api/aios/filter',
    statData: 'api/aios/month',
    import: 'api/import/aios',
    getInStock: 'api/aios/status/0',
    getActive: 'api/aios/status/1',
    getMaintenance: 'api/aios/status/2',
    getBrokenDown: 'api/aios/status/3',
    getAssigned: 'api/aios/status/4',
    dissocierAio: 'api/dissociate/aios/',
    activerAio: 'api/aio/',
    contactEditor: 'api/contact/editor/vehicle/',
    deleteCardData: 'api/data/aios/',
    exportData: 'api/export/data/aio/',
    aioImages: 'api/images/aio/',
    aioPdf: 'api/pv/aio/',
    aioPv: 'api/pv/',
  },
  user: {
    admin: 'api/users/admin',
    client: 'api/users',
    userList: 'api/users/role/',
    deleteEditor: 'api/editor/',
    operator: 'api/operators',
    deleteOperator: 'api/operator',
    operators: 'api/users/role/operator/',
    detailOperator: 'api/operators/',
    editor: 'api/editors',
    editors: 'api/users/role/editor/',
    detailEditor: 'api/editor/',
    updatePassword: 'api/password/user/',
    aioEdited: 'api/aios/editor/',
    exportEditor: 'api/export/editors',
    exportOperator: 'api/export/operators',
    exportUser: 'api/export/users',
    user: 'api/users/role/user/',
    aioOperator: 'api/aio/operators/',
    filterOperator: 'api/operators/filter',
    filterEditor: 'api/editors/filter',
    filterUsers: 'api/users/filter',
    cities: 'api/cities',
    userVehicles: 'api/user/vehicles/',
    allOperatorVehicles: 'api/vehicle/operator/',
    allOperatorInactiveVehicles: 'api/vehicle/inactif/operator/',
    vehicle: 'api/vehicle/',
  },
  legalText: {
    condition: 'api/terms_conditions',
    notice: 'api/legal_notice',
    manuals: 'api/user_manuals',
  },
  dashboard: {
    newsBlock: 'api/aios/count',
    recentEditors: 'api/editors',
  },
  files: {
    upload: 'api/file',
  },
  cities: {
    filterCities: 'api/filter/city/',
    filterDepartments: 'api/filter/department',
    departementCities: 'api/cities/department/',
    departments: 'api/departments',
    cities: 'api/cities/',
  },
};

export { API_HOST, API, CONTENT };
