const actions = {
    EDIT_USAGE_NOTICE_SUCCESS: 'EDIT_USAGE_NOTICE_SUCCESS',
    GET_USAGE_NOTICE_SUCCESS: 'GET_USAGE_NOTICE_SUCCESS',

    /* edit legal mentions */
    editUsageNoticeSucess: data => {
        return {
            type: actions.EDIT_USAGE_NOTICE_SUCCESS,
            data,
        }
    },

    /* get legal mentions */
    getUsageNoticeSucess: data => {
        return {
            type: actions.GET_USAGE_NOTICE_SUCCESS,
            data,
        }
    },
}

export default actions;