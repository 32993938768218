import React, { useState } from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';

const { SubMenu } = Menu;

const MenuItems = ({ toggleCollapsed }) => {
  const { path } = useRouteMatch();
  var pathName = window.location.pathname.split('/');
  const [openKeys, setOpenKeys] = useState(pathName.length > 2 ? pathName.slice(2, pathName.length) : []);
  /* click on sub menu with item */
  const onOpenChange = keys => {
    setOpenKeys([keys.pop()]);
  };
  /* click on sub menu without items */
  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={'inline'}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      defaultSelectedKeys={[window.location.pathname.split('/').pop() === "admin" ? 'dashboard' : window.location.pathname.split('/').pop()]}
    >
      {/* Dashboard */}
      <Menu.Item key="dashboard"
        icon={
          <NavLink className="menuItem-iocn" to={`${path}`}>
            <FeatherIcon icon="home" />
          </NavLink>
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          Dashboard
        </NavLink>
      </Menu.Item>

      {/* AIO */}
      <SubMenu key="BHBO" icon={<FeatherIcon icon="cpu" />} title="Gestion des BHBO">
        {/* Actif AIO */}
        <Menu.Item key="actif">
          <NavLink onClick={toggleCollapsed} to={`${path}/AIO/actif`}>
            BHBO Activés
          </NavLink>
        </Menu.Item>
        {/* Inactif AIO */}
        <Menu.Item key="inactif">
          <NavLink onClick={toggleCollapsed} to={`${path}/AIO/inactif`}>
            Inventaires
          </NavLink>
        </Menu.Item>
      </SubMenu>

      {/* Users */}
      <SubMenu key="users" icon={<FeatherIcon icon="users" />} title="Utilisateurs">
        {/* Conducteurs */}
        <Menu.Item key="clients">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/conducteurs`}>
          Conducteurs
          </NavLink>
        </Menu.Item>
        {/* Exploitants */}
        <Menu.Item key="exploitants">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/exploitants`}>
            Exploitants
          </NavLink>
        </Menu.Item>
        {/* Monteurs */}
        <Menu.Item key="monteurs">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/monteurs`}>
            Monteurs
          </NavLink>
        </Menu.Item>
        {/* Monteurs */}
        {/* <Menu.Item key="admins">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/admins`}>
            Admins
          </NavLink>
        </Menu.Item> */}
      </SubMenu>
      {/* Notices */}
      <SubMenu key="notices" icon={<FeatherIcon icon="file-text" />} title="Gestion des notices">
        {/* Conditions */}
        <Menu.Item key="conditions">
          <NavLink onClick={toggleCollapsed} to={`${path}/notices/condition-generale`}>
            Conditions générales
          </NavLink>
        </Menu.Item>
        {/* Mentions legales */}
        <Menu.Item key="legal">
          <NavLink onClick={toggleCollapsed} to={`${path}/notices/mentions-legales`}>
            Mentions légales
          </NavLink>
        </Menu.Item>
        {/* Notices */}
        <Menu.Item key="notice">
          <NavLink onClick={toggleCollapsed} to={`${path}/notices/notices-utilisation`}>
            Notices d’utilisation
          </NavLink>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
