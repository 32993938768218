const actions = {
    ALL_EDITOR_SUCCESS: 'ALL_EDITOR_SUCCESS',
    DETAIL_EDITOR_SUCCESS: 'DETAIL_EDITOR_SUCCESS',
    EDIT_EDITOR_SUCCESS: 'EDIT_EDITOR_SUCCESS',
    ALL_AIO_EDITED_SUCCESS: 'ALL_AIO_EDITED_SUCCESS',
    UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
    DELETE_EDITOR_SUCCESS: 'DELETE_EDITOR_SUCCESS',
    ADD_EDITOR_SUCCESS: 'ADD_EDITOR_SUCCESS',
    EXPORT_EDITOR_SUCCESS: 'EXPORT_EDITOR_SUCCESS',
    GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',
    CLEAR_DATA: 'CLEAR_DATA',
    FILTER_EDITOR_SUCCESS: 'FILTER_EDITOR_SUCCESS',


    /* action all editors */
    allEditorsSuccess: data => {
        return {
            type: actions.ALL_EDITOR_SUCCESS,
            data,
        }
    },
    /* action all editors */
    detailEditorSuccess: data => {
        return {
            type: actions.DETAIL_EDITOR_SUCCESS,
            data,
        }
    },
    /* action edit editor */
    editEditorSuccess: data => {
        return {
            type: actions.EDIT_EDITOR_SUCCESS,
            data
        }
    },
    /* all aio edited */
    allAioEditedSuccess: data => {
        return {
            type: actions.ALL_AIO_EDITED_SUCCESS,
            data
        }
    },
    /* update user password */
    updatePasswordSuccess: data => {
        return {
            type: actions.UPDATE_PASSWORD_SUCCESS,
            data
        }
    },
    /* delete Editor */
    deleteEditorSuccess: data => {
        return {
            type: actions.DELETE_EDITOR_SUCCESS,
            data
        }
    },
    /* add editor */
    addEditorSuccess: data => {
        return {
            type: actions.ADD_EDITOR_SUCCESS,
            data
        }
    },
    /* detail aio */
    exportEditorSuccess: data => {
        return {
            type: actions.EXPORT_EDITOR_SUCCESS,
            data,
        }
    },
    /* get cities */
    getCitiesSuccess: data => {
        return {
            type: actions.GET_CITIES_SUCCESS,
            data,
        }
    },
    /* clear data */
    clearDataSuccess: data => {
        return {
            type: actions.CLEAR_DATA,
            data,
        }
    },
    /* filter editor */
    filterSuccess: data => {
        return {
            type: actions.FILTER_EDITOR_SUCCESS,
            data,
        }
    },
}

export default actions