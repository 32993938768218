const actions = {
    EDIT_LEGAL_MENTION_SUCCESS: 'EDIT_LEGAL_MENTION_SUCCESS',
    GET_LEGAL_MENTION_SUCCESS: 'GET_LEGAL_MENTION_SUCCESS',

    /* edit legal mentions */
    editLegalMentionSucess: data => {
        return {
            type: actions.EDIT_LEGAL_MENTION_SUCCESS,
            data,
        }
    },

    /* get legal mentions */
    getLegalMentionSucess: data => {
        return {
            type: actions.GET_LEGAL_MENTION_SUCCESS,
            data,
        }
    },
}

export default actions;