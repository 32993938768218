import actionsAlert from './actions';

const { ALERT_SUCCESS, ALERT_ERROR, ALERT_HIDE } = actionsAlert;

const initialState = {
    msg: '',
    type: '',
}

const alertReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case ALERT_SUCCESS:
            return {
                ...state,
                msg: data.msg,
                type: data.type
            };
        case ALERT_ERROR:
            return {
                ...state,
                msg: data.msg,
                type: data.type
            };
        case ALERT_HIDE:
            return {
                ...state,
                msg: data.msg,
                type: data.type
            };
        default:
            return state;
    }
}

export { alertReducer };