import actions from './actions';

const { ADD_FILE_SUCCESS, UPLOAD_FILE_SUCCESS } = actions;

/* file state */
const initialState = {
    addFile: {},
    id: {}
}

/* aio reducer */
const fileReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case ADD_FILE_SUCCESS:
            return { ...state, addFile: data };
        case UPLOAD_FILE_SUCCESS:
            return { ...state, id: data };
        default:
            return state;
    }
};
export { fileReducer };