import actions from "./actions";
const { ALL_OPERATOR_SUCCESS, EDIT_OPERATOR_SUCCESS, DETAIL_OPERATOR_SUCCESS,
    ADD_OPERATOR_SUCCESS, EXPORT_OPERATOR_SUCCESS, ALL_AIO_OPERATOR_SUCCESS, FILTER_OPERATOR_INACTIVE_VEHICLE_SUCCESS,
    DELETE_OPERATOR_SUCCESS, FILTER_OPERATOR_SUCCESS, GET_OPERATOR_VEHICLE_SUCCESS, GET_OPERATOR_INACTIVE_VEHICLE_SUCCESS, CLEAR_DATA, DELETE_VEHICLE } = actions;
/* operator state */
const initialStateALL = {
    operators: [],
    singleOperator: [],
    exportedData: [],
    detailOperator: {},
    aioOperator: [],
    filter: [],
    editSuccess: false,
    successAlert: false,
    deleteOperator: false,
    vehicles: [],
    inactiveVehicles: [],
    filterInactiveVehicles: [],
    deleteVehicle: false,
}
/* operator reducer */
const operatorReducer = (state = initialStateALL, action) => {
    const { type, data } = action;
    switch (type) {
        case ALL_OPERATOR_SUCCESS:
            return {
                ...state,
                operators: data,
            };
        case DETAIL_OPERATOR_SUCCESS:
            return {
                ...state,
                detailOperator: data,
            };
        case GET_OPERATOR_VEHICLE_SUCCESS:
            return {
                ...state,
                vehicles: data,
            };
        case GET_OPERATOR_INACTIVE_VEHICLE_SUCCESS:
            return {
                ...state,
                inactiveVehicles: data,
            };
        case FILTER_OPERATOR_INACTIVE_VEHICLE_SUCCESS:
            return {
                ...state,
                inactiveVehicles: data,
            };
        case FILTER_OPERATOR_SUCCESS:
            return {
                ...state,
                filter: data,
            };
        case EDIT_OPERATOR_SUCCESS:
            return {
                ...state,
                editSuccess: data,
            }
        case ADD_OPERATOR_SUCCESS:
            return {
                ...state,
                successAlert: data,
            };
        case CLEAR_DATA:
            return {
                ...state,
                detailOperator: {},
            };
        case EXPORT_OPERATOR_SUCCESS:
            return {
                ...state,
                exportedData: data
            };
        case ALL_AIO_OPERATOR_SUCCESS:
            return {
                ...state,
                aioOperator: data,
            };
        case DELETE_OPERATOR_SUCCESS:
            return {
                ...state,
                deleteOperator: data,
            };
        case DELETE_VEHICLE:
            return {
                ...state,
                deleteVehicle: data,
            };
        default:
            return state
    };
};
export { operatorReducer };