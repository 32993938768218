const actions = {
    FILTER_CITIES: 'FILTER_CITIES',
    FILTER_DEPARTMENTS: 'FILTER_DEPARTMENTS',
    ALL_DEPARTMENTS: 'ALL_DEPARTMENTS',
    ALL_DEPARTMENT_CITIES: 'ALL_DEPARTMENT_CITIES',
    GET_CITY: 'GET_CITY',
    CLEAR_DATA_CITY: 'CLEAR_DATA_CITY',

    /* clear data city */
    clearDataCitySuccess: data => {
        return {
            type: actions.CLEAR_DATA_CITY,
            data,
        }
    },
    /* filter departments */
    FilterDepartmentsSuccess: data => {
        return {
            type: actions.FILTER_DEPARTMENTS,
            data,
        }
    },
    /* filter cities */
    FilterCitiesSuccess: data => {
        return {
            type: actions.FILTER_CITIES,
            data,
        }
    },
    /* get all departments */
    getAllDepartmentsSuccess: data => {
        return {
            type: actions.ALL_DEPARTMENTS,
            data,
        }
    },
    /* get all department cities */
    getAllDepartmentcitiesSuccess: data => {
        return {
            type: actions.ALL_DEPARTMENT_CITIES,
            data,
        }
    },
    /* get all department cities */
    getCitySuccess: data => {
        return {
            type: actions.GET_CITY,
            data,
        }
    },
}


export default actions;